import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GlobalData {
    static userId: string; // 用户id
    static userCode: string; // 用户名
    static nickName: string; // 真实姓名
    static mobilePhone: string; // 手机号码
    static token: string; // token

}
