import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
/*import { SocialSharing } from '@ionic-native/social-sharing/ngx';*/
import {PhotoLibrary} from '@ionic-native/photo-library/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {Network} from '@ionic-native/network/ngx';
import {AppMinimize} from '@ionic-native/app-minimize/ngx';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';


import {HttpClientModule} from '@angular/common/http';

import {NavController, Platform} from '@ionic/angular';
import {NativeService} from './providers/NativeService';
import {Helper} from './providers/Helper';
/*import {UserInfo} from './interfaces/UserInfo';
import {AuthService} from './services/auth.service';*/
import {Storage} from './providers/Storage';
import {GlobalData} from './providers/GlobalData';
import {UpdateAppService} from './providers/UpdateAppService';
import {mergeMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import { Camera } from '@ionic-native/camera/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { SignaturePadModule } from 'angular2-signaturepad'; // 签名插件
import { FormsModule} from '@angular/forms';
// import { MultiPickerModule } from 'ion-multi-picker'; //省市区
import { CallNumber } from '@ionic-native/call-number';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginGuardGuard } from 'src/app/guard/login-guard.guard';
@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [BrowserModule,
         IonicModule.forRoot(),
         AppRoutingModule,
         HttpClientModule,
         SignaturePadModule,
         FormsModule,
         BrowserAnimationsModule, ],
    providers: [
        StatusBar,
        SplashScreen,
        AppVersion,
        FileTransfer,
        File,
        FileOpener,
        LocalNotifications,
        AndroidPermissions,
        PhotoLibrary,
        InAppBrowser,
        Network,
        AppMinimize,
        SQLite,
        SQLitePorter,
        Camera,
        ImagePicker,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        LoginGuardGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(public platform: Platform,
                public nav: NavController,
                public router: Router,
                public helper: Helper,
                public native: NativeService
    ) {
        this.initializeApp();
        // 处理安卓返回按钮事件，更多情况请看 tabs.page.ts
     /*   this.platform.backButton.subscribe(() => {
            if (this.router.url === '/login' && !GlobalData.token) {
                this.native.appMinimize();
            }
        });*/
    }
    initializeApp() {
        this.platform.ready().then(() => {
            const oldToken = Storage.localStorage.get('token'); // 从缓存中获取token
            /*      if (oldToken) {
                      GlobalData.token = oldToken; // 旧token作为请求头参数，用旧token获取新token
                      this.auth.getNewToken().pipe(
                          mergeMap(token => {
                              GlobalData.token = token;
                              Storage.localStorage.set('token', token);
                              return this.auth.getUserInfo();
                          })
                      ).subscribe((userInfo: UserInfo) => {
                          this.helper.loginSuccessHandle(userInfo);
                          this.nav.navigateRoot('/tabs/tab1'); // 会根据url自动匹配路由
                      }, () => {
                          this.nav.navigateRoot('/login');
                      });
                  } else {
                      this.nav.navigateRoot('/login');
                  }*/
         /*   this.native.setStatusBarStyle();
            this.native.hideSplashScreen();*/
            // this.versionService.checkVersion();
        });
    }
}
