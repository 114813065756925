import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, AlertController, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NativeService } from './providers/NativeService';
import { UpdateAppService } from './providers/UpdateAppService';
import { Helper } from './providers/Helper';
import { DataProvider } from './providers/DataProvider';
import { ApiService } from './services/ApiService';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        private alertController: AlertController,
        public navController: NavController,
        public native: NativeService,
        public helper: Helper,
        public updateApp: UpdateAppService,
        public apiService: ApiService,
        public dataProvider: DataProvider
    ) {
        this.initializeApp();
        this.backButtonEvent();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            /*this.statusBar.styleDefault();
            this.splashScreen.hide();*/
            this.native.setStatusBarStyle();
            const that = this;
            if (this.helper.isMobile()) {
                setTimeout(function () {
                    that.native.hideSplashScreen();
                }, 1000);
                this.updateApp.checkUpdate();
                this.dataProvider.initDatabase();
            }
        /*    const oldUser = this.apiService.getLoginUser(); // 从缓存中获取token
            if (oldUser) {
                console.log('app自动登录');
                const obj = {
                    mobilePhone: oldUser.mobilePhone,
                    password: oldUser.password
                };
                this.apiService.loginEncryPwd(obj).subscribe(res => {
                    this.apiService.saveLoginUser(res);
                    this.helper.loginSuccessHandle(res);
                });
            }*/
            this.platform.resume.subscribe();
        });
    }

    //android通过返回按钮退出应用
    lastTimeBackPress = 0;
    timePeriodToExit = 2000;

    backButtonEvent() {
        this.platform.backButton.subscribe((e) => {
            if (this.router.url.indexOf('home') > -1 || this.router.url.indexOf('login') > -1 || this.router.url.indexOf('mine') > -1) {
                if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
                    navigator['app'].exitApp(); //退出APP
                } else {
                    this.presentAlertConfirm();
                    this.lastTimeBackPress = new Date().getTime();
                }
                // navigator['app'].exitApp(); //ionic4 退出APP的方法
            } else if (this.router.url.indexOf('register/patrol-boss/detail') > -1 || this.router.url.indexOf('register/detail') > -1) {
                this.navController.navigateBack("register/list");
            }

        });
    }

    async presentAlertConfirm() {

        const alert = await this.alertController.create({
            // header: 'Confirm!',
            message: '您要退出APP吗?',
            buttons: [
                {
                    text: '取消',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                    }
                }, {
                    text: '退出',
                    handler: () => {
                        navigator['app'].exitApp();
                    }
                }
            ]

        });

        await alert.present();
    }
}
