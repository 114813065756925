import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'changepwd', loadChildren: './mine/changepwd/changepwd.module#ChangepwdPageModule' },
  { path: 'cargo-details', loadChildren: './cargo/details/details.module#DetailsPageModule' },
  { path: 'trade-details', loadChildren: './trade/details/details.module#DetailsPageModule' },
  { path: 'pay-details', loadChildren: './pay/details/details.module#DetailsPageModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'complete-info', loadChildren: './register/complete-info/complete-info.module#CompleteInfoPageModule' },
  { path: 'start-modal', loadChildren: './cargo/index/start-modal/modal.module#ModalPageModule' },
  { path: 'vehicle-modal', loadChildren: './cargo/index/vehicle-modal/vehicle-modal.module#VehicleModalPageModule' },
  { path: 'order-details', loadChildren: './order/details/details.module#DetailsPageModule' },
  { path: 'wallet-list', loadChildren: './wallet/walletList/walletList.module#WalletListModule' },
  { path: 'wallet-detail', loadChildren: './wallet/walletDetail/walletDetail.module#WalletDetailModule' },
  { path: 'my-bankCard', loadChildren: './wallet/myBankCard/myBankCard.module#MyBankCardModule' },
  { path: 'add-card', loadChildren: './wallet/addCard/addCard.module#AddCardModule' },
  { path: 'withdrawal', loadChildren: './wallet/withdrawal/withdrawal.module#WithdrawalModule' }
  
];
@NgModule({
  imports: [
   // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
