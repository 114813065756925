import {SQLite, SQLiteObject} from '@ionic-native/sqlite/ngx';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataProvider {

    database: SQLiteObject;

    constructor(private sqlite: SQLite) {

    }

    public initDatabase() {
        this.sqlite.create({
            name: 'myApp.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            this.database = db;
            const loadPlanSql = 'CREATE TABLE IF NOT EXISTS bas_load_plan (\n' +
                '  id TEXT  PRIMARY KEY NOT NULL ,\n' +
                '  planName TEXT NOT NULL ,\n' +
                '  planCode TEXT NOT NULL ,\n' +
                '  loadExplainImg TEXT DEFAULT NULL ,\n' +
                '  shape TEXT NOT NULL ,\n' +
                '  weight REAL NOT NULL  ,\n' +
                '  useableCar TEXT NOT NULL ,\n' +
                '  fixedMaterial TEXT NOT NULL ,\n' +
                '  remark TEXT DEFAULT NULL ,\n' +
                '  createUserId TEXT DEFAULT NULL ,\n' +
                '  createUser TEXT DEFAULT NULL ,\n' +
                '  createTime TEXT DEFAULT NULL ,\n' +
                '  updateUserId TEXT DEFAULT NULL ,\n' +
                '  updateUser TEXT DEFAULT NULL ,\n' +
                '  updateTime TEXT DEFAULT NULL ,\n' +
                '  deleteFlag INTEGER NOT NULL  \n' +
                ') ';

            const loadPlanExpainSql = 'CREATE TABLE bas_load_explain (\n' +
                '  id TEXT PRIMARY KEY NOT NULL ,\n' +
                '  planId TEXT NOT NULL ,\n' +
                '  explainText varchar(255) NOT NULL ,\n' +
                '  explainType INTEGER NOT NULL  ,\n' +
                '  sortNum INTEGER NOT NULL \n' +
                '  \n' +
                ') ';
            db.executeSql(loadPlanSql, [])
                .then(() => console.log('init database1 successfully')).catch(e => console.log(e));
            db.executeSql(loadPlanExpainSql, [])
                .then(() => console.log('init database2 successfully')).catch(e => console.log(e));
        }).catch(e => console.error(e));
    }


}
