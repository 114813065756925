// environment example file
// You should copy this file and rename to `environment.conf.ts` (do NOT add to version control)
// place your own config to that file

export const devServers = {
       //appServerUrl: 'http://localhost:9001/',
    //appServerUrl: 'http://47.108.76.117:8090/',//万盛正式服务器
    appServerUrl: "https://wlhy-boot.cqws56.com/",//万盛正式服务器
    //appServerUrl: 'http://122.112.248.61:8055/',//华畴测试服务器
    //appServerUrl: 'http://192.168.3.9:8090',//王建齐服务器
    imgUrl: 'http://47.105.131.99:8050/sys/common/view/',
    fileServerUrl: 'https://yanxiaojun617.com/fileService/',
    requestTimeout: 200000,
    defaultAvatar: './assets/avatar/default.png',
    rsaPublicKey: 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCPzCRijQTW+fxen+IgGddcA+lQLQ9c3LdwKfR7CHK+LWQ1oMfkN69xoWOTs2Sk77vJ' +
        '86i/bn7jOmn6lQAqNndET80vhqFBV2r/0n0cZVke3vFZTVLd/hEfotIsRo4HbCPwK0UwA1LuuEsr7fqgHG1RKnK/SFLYBmgg85IE6PP8gwIDAQAB'
};

export const prodServers = {
    appServerUrl: 'http://10.208.101.249:8061/api/',
    imgUrl: 'http://10.208.101.249:8061/sys/common/view/',
    fileServerUrl: 'https://yanxiaojun617.com/fileService/',
    requestTimeout: 200000,
    defaultAvatar: './assets/avatar/default.png',
    rsaPublicKey: 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCPzCRijQTW+fxen+IgGddcA+lQLQ9c3LdwKfR7CHK+LWQ1oMfkN69xoWOTs2Sk77vJ' +
        '86i/bn7jOmn6lQAqNndET80vhqFBV2r/0n0cZVke3vFZTVLd/hEfotIsRo4HbCPwK0UwA1LuuEsr7fqgHG1RKnK/SFLYBmgg85IE6PP8gwIDAQAB'
};
