import { Injectable } from '@angular/core';

import { HttpService } from '../providers/HttpService';

import { Storage } from '../providers/Storage';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(public http: HttpService) { }

  // login
  public login(data: any = {}) {
    return this.http.post('/driver/login', data);
  }
  public login_code(data: any = {}) {
    return this.http.post('/login_code?code='+data);
  }
  public logout() {
    return this.http.post('/logout');
    return this.http.post('/logout');
  }
  // login
  /*public loginEncryPwd(data: any = {}) {
    return this.http.post('loginEncryptPwd', data);
  }*/

  public loginById(id: string) {
    return this.http.post('sys/login' + id);
  }

  public saveLoginUser(user) {
    Storage.localStorage.set('user', JSON.stringify(user));
  }

  public getLoginUser() {
    let userinfo = Storage.localStorage.get('user');
    if (userinfo != null) {
      userinfo = JSON.parse(userinfo);
      return userinfo;
    } else {
      return null;
    }
  }

  // 修改密码
  public updatePwd(data: any) {
    return this.http.post('/sys/changePwd', data);
  }

  // 货源
  public getPageByReleased(data: any) {
    return this.http.post('/api/deal/cargo/getPageByReleased', data);
  }
  // 交易管理
  public getPageByDriver(data: any) {
    return this.http.post('/api/deal/apply/getPageByDriver', data);
  }
  public editPrice(data: any) {
    return this.http.post('/api/deal/apply/editPrice', data); //修改报价
  }
  public apply(data: any) {
    return this.http.post('/api/deal/apply/apply', data); //申请交易
  }
  // 付款管理
  public getPageByPay(data: any) {
    return this.http.post('/api/deal/payable/getPageByDriver', data);
  }
   // 付款详情
   public getPageByPayable(data: any) {
    return this.http.post('/api/deal/payableRecord/getPageByPayable', data);
  }
  // 运单管理
  public orderGetPageBy(data: any) {
    return this.http.post('/api/deal/order/getPageByDriver', data);
  }
  public orderSend(data: any) {
    return this.http.post('/api/deal/order/send?id=' + data);
  }
  public orderReceive(data: any) {
    return this.http.post('/api/deal/order/arrive' , data);
  }
  public reEvaluation(data1: any,data2: any) {
    return this.http.post('/deal/order/reEvaluation?id=' + data1+'&num='+data2);
  }
  // 司机信息
  public getInfo() {
    return this.http.post('/api/base/driver/getInfo');
  }
  // 获取验证码
  public sendSmsCode(data: any) {
    return this.http.post('/sendSmsCode?mobilePhone=' + data);
  }
  // 注册
  public register(data: any) {
    return this.http.post('/register', data);
  }
  // 验证验证码
  public validateSmsCode(data: any) {
    return this.http.post('/validateSmsCode', data);
  }
  // 字典
  public getByDictCode(data: any) {
    return this.http.post('/sys/dictItem/getByDictCode?dictCode=' + data);
  }
  // 上传回单
  public uploadImg(data: any) {
    return this.http.post('/sys/common/upload', data);
  }
  // 上传身份证
  public upIdCard(data: any) {
    return this.http.post('/api/base/driver/idCardDiscern', data);
  }
   // 上传驾驶证
   public upDriverCard(data: any) {
    return this.http.post('/api/base/driver/idCardDiscern', data);
  }
  // 上传行驶证
  public upDrivingCard(data: any) {
    return this.http.post('/api/base/driver/idCardDiscern', data);
  }
   // 上传地理位置
  public Upaddress(data1: any,data2: any,data3: any) {
    return this.http.post('/vehicle/onlineTracing?orderId='+ data1+'&lng='+data2+'&lat='+data3);
  }
  // 获取钱包余额
  public getMoney() {
    return this.http.post('/wallet/getMoney');
  }
  // 获取钱包余额
  public getWalletDetail() {
    return this.http.post('/wallet/walletVO1List_driver');
  }
  // 获取银行卡列表
  public getCardList() {
    return this.http.post('/sys/bank/getList');
  }
  // 绑定一张银行卡
  public editBank(data: any) {
    return this.http.post('/sys/bank/editBank', data);
  }
  // 删除一张银行卡
  public deleteBank(data: any) {
    return this.http.post(`/sys/bank/deleteBank?id=${data.id}`);
  }
  public withdrawal(data: any) {
    return this.http.post(`/wallet/withdraw/driver`,data);
  }


}
